import React from 'react';
import {Button} from '@chakra-ui/react';
import {OAuthProvider, GoogleAuthProvider} from 'firebase/auth';
import {FirebaseError} from 'firebase/app';
import {GoogleLogoIcon} from 'dp-chakra/src/components/icons/GoogleLogoIcon';
import {useDpErrorToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {useSignInWithRedirect} from '../../../packages/firebase-core/src/hooks/use-sign-in-with-redirect';

export const SignInWithGoogleButton = () => {
  const dpErrorToast = useDpErrorToast();

  const {mutate: signInWithRedirectMutation} = useSignInWithRedirect({
    onError(error) {
      if (error instanceof FirebaseError) {
        dpErrorToast({
          title: error.message,
        });
      } else {
        dpErrorToast({
          title: 'Google ログインに失敗しました。',
        });
      }
    },
  });

  const handleClick = () => {
    const provider = new OAuthProvider(GoogleAuthProvider.PROVIDER_ID);
    signInWithRedirectMutation({provider});
  };

  return (
    <Button
      variant="outline"
      height="44px"
      textColor="black"
      borderColor="blueGray.500"
      colorScheme="blueGray"
      leftIcon={
        <GoogleLogoIcon position="absolute" top={3} left={2} boxSize={5} />
      }
      onClick={handleClick}
    >
      Googleで続ける
    </Button>
  );
};
