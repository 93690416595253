import {type ComponentMultiStyleConfig} from '@chakra-ui/react';
import {colors} from '../colors';

export const Card: ComponentMultiStyleConfig = {
  parts: ['card', 'cardHeader', 'cardContent', 'cardTitle', 'cardDescription'],
  baseStyle: (props) => ({
    card: {
      shadow: 'md',
      borderRadius: 'md',
      bgColor: props.colorMode === 'light' ? colors.white : undefined,
    },
    cardHeader: {
      borderBottom: '1px',
      borderColor: 'blueGray.100',
    },
    cardTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '2xl',
    },
    cardDescription: {
      color: 'gray.600',
    },
  }),
  sizes: {
    md: {
      cardHeader: {
        padding: 4,
      },
      cardContent: {
        padding: 4,
      },
      cardTitle: {
        marginBottom: 4,
      },
    },
    lg: {
      cardHeader: {
        padding: 4,
      },
      cardContent: {
        padding: 10,
      },
      cardTitle: {
        marginBottom: 10,
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
