import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {accountServerGraphQl} from '../../const/env';
import {getOrCreateIdTokenByFirebase} from '../../packages/firebase-core/src/services/get-or-create-id-token-by-firebase';
import {firebaseAuth} from '../../packages/firebase-core/src/auth/auth';

type CustomHeaders = {
  authorization: string;
};

type Context = {
  headers: CustomHeaders;
};

const authLink = setContext(async (_, {headers}) => {
  let idToken: string | undefined;
  try {
    idToken = await getOrCreateIdTokenByFirebase(firebaseAuth);
  } catch {
    // IdTokenが取得できなくても処理は進める
  }

  const customHeaders: CustomHeaders = {
    authorization: idToken ? `Bearer ${idToken}` : '',
  };

  const context: Context = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: {
      ...headers,
      ...customHeaders,
    },
  };

  return context;
});

const httpLink = createHttpLink({
  uri: accountServerGraphQl,
});

export const createApolloClient = () => {
  return new ApolloClient({
    // Concatを使用しない様に表示されるが、配列のconcatではなくclassのconcatのため無効とする
    // eslint-disable-next-line unicorn/prefer-spread
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
