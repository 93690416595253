import React, {type ComponentProps} from 'react';
import {type RegisterOptions, useFormContext} from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import {type HtmlAutoComplete} from '../../../types/html-types';
import {FormLabel, type FormLabelProps} from '../FormLabel/FormLabel';
import {createFormErrorMessage} from '../../../features/react-hook-form/services/create-form-error-message';

export type FormInputProps = {
  readonly id: string;
  readonly label?: string;
  readonly autoComplete?: HtmlAutoComplete;
  readonly helperText?: string;
  readonly leftIcon?: React.ReactNode;
  readonly rightIcon?: React.ReactNode;
  readonly inputLeftElement?: React.ReactNode;
  readonly inputRightElement?: React.ReactNode;
  readonly containerProps?: ComponentProps<typeof Flex>;
  readonly formLabelProps?: FormLabelProps;
  readonly inputProps?: ComponentProps<typeof Input>;
  readonly fullWidth?: boolean;
  readonly options?: RegisterOptions;
};

export const FormInput: React.FC<FormInputProps> = (props) => {
  const {
    id,
    label,
    autoComplete,
    helperText,
    leftIcon,
    rightIcon,
    inputLeftElement,
    inputRightElement,
    containerProps,
    formLabelProps,
    inputProps,
    fullWidth,
    options,
  } = props;
  const {
    register,
    formState: {errors},
  } = useFormContext<Record<typeof id, string>>();

  const error = errors[id];

  return (
    <FormControl isInvalid={Boolean(error)}>
      <Flex
        flexDirection={{base: 'column', xl: 'row'}}
        alignItems={{base: 'start', xl: 'center'}}
        {...containerProps}
      >
        {label && (
          <FormLabel
            htmlFor={id}
            isRequired={Boolean(options?.required)}
            {...formLabelProps}
          >
            {label}
          </FormLabel>
        )}
        <Box
          flex={{base: 'unset', xl: 1}}
          width={{base: '100%', xl: fullWidth ? '100%' : 'unset'}}
        >
          <InputGroup alignItems="center">
            {inputLeftElement}
            {leftIcon && (
              <InputLeftElement pointerEvents="none">
                {leftIcon}
              </InputLeftElement>
            )}
            <Input
              id={id}
              autoComplete={autoComplete}
              {...inputProps}
              {...register(id, options)}
            />
            {rightIcon && (
              <InputRightElement pointerEvents="none">
                {rightIcon}
              </InputRightElement>
            )}
            {inputRightElement}
          </InputGroup>
          <FormErrorMessage>
            {createFormErrorMessage(error?.type, options)}
          </FormErrorMessage>
          {helperText && (
            <FormHelperText color="fontColor.sub" whiteSpace="pre-wrap">
              {helperText}
            </FormHelperText>
          )}
        </Box>
      </Flex>
    </FormControl>
  );
};
