import React from 'react';
import {Icon} from '@chakra-ui/react';
import {type IconProps} from './types';

export const AppleLogoIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2227 6.38461C15.0547 6.38461 16.0977 5.80483 16.7188 5.03179C17.2812 4.33121 17.6914 3.35283 17.6914 2.37444C17.6914 2.24158 17.6797 2.10871 17.6562 2C16.7305 2.03624 15.6172 2.64018 14.9492 3.44946C14.4219 4.06548 13.9414 5.03179 13.9414 6.02225C13.9414 6.1672 13.9648 6.31214 13.9766 6.36046C14.0352 6.37254 14.1289 6.38461 14.2227 6.38461ZM11.293 21C12.4297 21 12.9336 20.2149 14.3516 20.2149C15.793 20.2149 16.1094 20.9758 17.375 20.9758C18.6172 20.9758 19.4492 19.7921 20.2344 18.6325C21.1133 17.3039 21.4766 15.9994 21.5 15.939C21.418 15.9148 19.0391 14.9123 19.0391 12.0979C19.0391 9.65798 20.9141 8.5588 21.0195 8.47425C19.7773 6.63827 17.8906 6.58996 17.375 6.58996C15.9805 6.58996 14.8438 7.45963 14.1289 7.45963C13.3555 7.45963 12.3359 6.63827 11.1289 6.63827C8.83203 6.63827 6.5 8.59504 6.5 12.2912C6.5 14.5861 7.36719 17.014 8.43359 18.5842C9.34766 19.9129 10.1445 21 11.293 21Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
};
