import dayjs, {type ConfigType, type Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ja';

export const initDayjs = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');
  dayjs.locale('ja');
};

export const isExpired = (
  expiredAt?: string | number | dayjs.Dayjs | Date,
): boolean => {
  if (!expiredAt) {
    return false;
  }

  return dayjs().tz().isAfter(dayjs(expiredAt).tz());
};

export const createSequentialDates = (
  days: number,
  options?: {firstDate?: ConfigType},
): Dayjs[] => {
  const firstDate = dayjs(options?.firstDate).tz();

  const dates: Dayjs[] = [];
  for (let index = 0; index < days; index++) {
    const date = firstDate.add(index, 'day');
    dates.push(date);
  }

  return dates;
};
