import {type ComponentSingleStyleConfig} from '@chakra-ui/react';

export const Badge: ComponentSingleStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
};
