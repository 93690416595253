import React from 'react';
import {
  Badge,
  FormLabel as ChakraFormLabel,
  type FormLabelProps as ChakraFormLabelProps,
} from '@chakra-ui/react';

export type FormLabelProps = {
  readonly isRequired?: boolean;
  readonly isShowOptionalBadge?: boolean;
} & ChakraFormLabelProps;

export const FormLabel: React.FC<FormLabelProps> = (props) => {
  const {isRequired, isShowOptionalBadge, children, ...formLabelProps} = props;

  return (
    <ChakraFormLabel
      fontWeight="bold"
      height="fit-content"
      width={{base: 'unset', xl: '11rem'}}
      {...formLabelProps}
    >
      {children}
      {isRequired && (
        <Badge
          colorScheme="red"
          variant="solid"
          marginLeft={2}
          marginBottom="2px"
        >
          必須
        </Badge>
      )}
      {isShowOptionalBadge && (
        <Badge
          colorScheme="blueGray"
          variant="solid"
          marginLeft={2}
          marginBottom="2px"
        >
          任意
        </Badge>
      )}
    </ChakraFormLabel>
  );
};
