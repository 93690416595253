import axios, {AxiosError} from 'axios';
import {firebaseAuth} from '../../firebase-core/src/auth/auth';

type SessionCreateRequest = {
  idToken: string;
  refreshToken: string;
};

export const sessionCreate = async () => {
  const idToken = await firebaseAuth.currentUser?.getIdToken();
  const refreshToken = firebaseAuth.currentUser?.refreshToken;

  if (!idToken || !refreshToken) {
    // このsession createはfirebaseのログイン成功後に実行されるため、
    // idTokenとrefreshTokenがundefinedの場合は想定上はない
    throw new Error(
      'ログインに失敗しました。再度ログインを行なってください。(token does not exist)',
    );
  }

  return (
    axios
      .post<any, void, SessionCreateRequest>(
        `${process.env.NEXT_PUBLIC_ACCOUNT_SERVER}/session/create`,
        {idToken, refreshToken},
        {
          withCredentials: true,
        },
      )
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((error: unknown) => {
        // @see https://docs.google.com/spreadsheets/d/1Knl9qRKG1Aco9syTSdZ8eMc6YX971JfbqN7OWP0S4cQ/edit#gid=0
        if (error instanceof AxiosError && error.response?.status === 400) {
          error.message =
            '接続エラーが発生しました。しばらく時間を置いて再度アクセスをお試しください。問題が解決されない場合はサポートまでお問い合わせください。';
        }

        throw error;
      })
  );
};
