import {
  type AuthProvider,
  type PopupRedirectResolver,
  signInWithRedirect,
} from 'firebase/auth';
import {useMutation} from '@tanstack/react-query';
import {firebaseAuth} from '../auth/auth';
import {convertToJpFirebaseError} from '../services/convert-to-jp-firebase-error';
import {type UseFirebaseMutationOptions} from '../types/types';

export type UseSignInWithRedirectRequest = {
  provider: AuthProvider;
  resolver?: PopupRedirectResolver;
};

export const useSignInWithRedirect = (
  options?: UseFirebaseMutationOptions<void, UseSignInWithRedirectRequest>,
) => {
  return useMutation({
    async mutationFn(parameters) {
      return signInWithRedirect(
        firebaseAuth,
        parameters.provider,
        parameters.resolver,
      );
    },
    ...options,
    onError(error, ...args) {
      const jpError = convertToJpFirebaseError(error);
      void options?.onError?.(jpError, ...args);
    },
  });
};
