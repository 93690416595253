import {callbackQueryKey} from 'dp-account/src/const/url-query';
import {isSsr} from 'dp-nextjs/src/utils/is';

export const toCallbackUrl = (callbackKey = callbackQueryKey): boolean => {
  if (isSsr) {
    return false;
  }

  const searchParameters = new URLSearchParams(window.location.search);
  const callbackUrl = searchParameters.get(callbackKey);
  if (callbackUrl) {
    window.location.href = callbackUrl;
    return true;
  }

  return false;
};
