import React from 'react';
import {
  CardHeader,
  type CardHeaderProps,
} from 'dp-chakra/src/components/core/Card';
import {useDpErrorToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {Button} from '@chakra-ui/react';
import {AccountLogo} from '../AccountLogoTemp/AccountLogoTemp';
import {useSignOut} from '../../../packages/account-server/hooks/use-sign-out';

type AccountCardHeaderProps = {
  readonly children?: React.ReactNode;
  readonly enableSignOutButton?: boolean;
} & CardHeaderProps;

export const AccountCardHeader: React.FC<AccountCardHeaderProps> = (props) => {
  const {children, enableSignOutButton, ...cardHeaderProps} = props;

  const errorToast = useDpErrorToast();

  const {signOut, isSignOutLoading} = useSignOut({
    onError(key, error) {
      errorToast({
        title: error.message,
      });
    },
  });

  const handleSignOut = async () => {
    signOut();
  };

  return (
    <CardHeader
      display="flex"
      justifyContent="space-between"
      backgroundColor={headerBackgroundColor()}
      {...cardHeaderProps}
    >
      <AccountLogo />

      {children}

      {enableSignOutButton && (
        <Button isLoading={isSignOutLoading} onClick={handleSignOut}>
          ログアウト
        </Button>
      )}
    </CardHeader>
  );
};

const headerBackgroundColor = () => {
  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'production': {
      return 'white';
    }

    case 'preview':
    case 'development': {
      return 'green.50';
    }

    default: {
      return 'gray.50';
    }
  }
};
