import {type GlobalStyles} from '@chakra-ui/theme-tools';

export const styles: GlobalStyles = {
  global: (_props) => ({
    'html, body': {},
    body: {
      color: 'fontColor.main',
    },
  }),
};
