import axios, {AxiosError} from 'axios';

export const sessionDelete = async () => {
  return (
    axios
      .post<any, void, void>(
        `${process.env.NEXT_PUBLIC_ACCOUNT_SERVER}/session/delete`,
        undefined,
        {
          withCredentials: true,
        },
      )
      // eslint-disable-next-line promise/prefer-await-to-then
      .catch((error: unknown) => {
        // @see https://docs.google.com/spreadsheets/d/1Knl9qRKG1Aco9syTSdZ8eMc6YX971JfbqN7OWP0S4cQ/edit#gid=0
        if (error instanceof AxiosError && error.response?.status === 401) {
          error.message =
            '認証エラーが発生しました。再度ログインをお試しください。問題が解決されない場合はサポートまでお問い合わせください。';
        }

        throw error;
      })
  );
};
