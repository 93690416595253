import React from 'react';
import {useRequireAnonymous} from '../../../hooks/use-require-anonymous';

type RequireSignInProps = {
  readonly children?: React.ReactNode;
};

export const RequireAnonymous: React.FC<RequireSignInProps> = (props) => {
  const {children} = props;
  const {isLoading} = useRequireAnonymous();

  if (isLoading) {
    return null;
  }

  return <>{children}</>;
};
