import {type LiteralUnion, type RegisterOptions} from 'react-hook-form';

const optionIds = [
  'required',
  'min',
  'max',
  'minLength',
  'maxLength',
  'validate',
  'pattern',
] as const;

export const createFormErrorMessage = (
  type?: LiteralUnion<keyof RegisterOptions, string>,
  options?: RegisterOptions,
) => {
  if (!type) {
    return '';
  }

  const errorMessage = getErrorMessage(options);
  if (errorMessage) {
    return errorMessage;
  }

  switch (type) {
    case 'required': {
      return 'この項目は必須です';
    }

    case 'validate': {
      return '指定された形式で入力してください';
    }

    case 'min': {
      return `${
        options?.min?.toString() ?? '指定された値'
      }以上の数値を入力してください`;
    }

    case 'max': {
      return `${
        options?.max?.toString() ?? '指定された値'
      }以下の数値を入力してください`;
    }

    case 'minLength': {
      return `${
        options?.minLength?.toString() ?? '指定された'
      }桁以上の文字数を入力してください`;
    }

    case 'maxLength': {
      return `${
        options?.maxLength?.toString() ?? '指定された'
      }桁以下の文字数を入力してください`;
    }

    default: {
      return 'この項目が認識できません';
    }
  }
};

const getErrorMessage = (options?: RegisterOptions): string | undefined => {
  if (!options) {
    return;
  }

  return optionIds
    .map((id) => {
      if (id === 'validate') {
        return undefined;
      }

      const value = options[id];
      if (typeof value === 'object' && 'message' in value) {
        return value.message;
      }

      return undefined;
    })
    .find(Boolean);
};
