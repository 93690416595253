import React from 'react';
import {useSignInStatusCheck} from '../../../packages/account-server/hooks/use-sign-in-status-check';

type SignInStatusCheckProps = {
  readonly children?: React.ReactNode;
};

export const SignInStatusCheck: React.FC<SignInStatusCheckProps> = (props) => {
  const {children} = props;

  useSignInStatusCheck();

  return <>{children}</>;
};
