import {type ComponentMultiStyleConfig} from '@chakra-ui/react';

export const Tabs: ComponentMultiStyleConfig = {
  parts: ['tabs', 'tabList', 'tab', 'tabPanels', 'tabPanel'],
  baseStyle() {
    return {
      tab: {
        lineHeight: 1,
        fontWeight: 'bold',
        _focus: {boxShadow: 'none'},
        _focusVisible: {boxShadow: 'var(--chakra-shadows-outline)'},
      },
    };
  },
  sizes: {
    sm: {
      tab: {
        paddingY: 3,
      },
    },
    md: {
      tab: {
        fontSize: 'md',
        paddingY: 5,
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
};
