import {initializeApp, type FirebaseOptions} from 'firebase/app';
import {getAuth} from 'firebase/auth';

const accountWebUrl = new URL(process.env.NEXT_PUBLIC_ACCOUNT_WEB);

const firebaseOptions: FirebaseOptions = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: accountWebUrl.hostname,
  databaseURL: `https://${
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_NAME ?? ''
  }.firebaseio.com`,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.NEXT_PUBLIC_FIREBASE_BUCKET ?? ''}.appspot.com`,
};

export const firebaseApp = initializeApp(firebaseOptions);

export const firebaseAuth = getAuth(firebaseApp);
