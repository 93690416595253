import {type ComponentMultiStyleConfig} from '@chakra-ui/react';
import {colors} from '../colors';

export const Progress: ComponentMultiStyleConfig = {
  parts: ['label', 'filledTrack', 'track'],
  baseStyle: {
    track: {
      borderRadius: '10rem',
    },
  },
  sizes: {},
  variants: {
    linearGradientBlue: {
      track: {
        backgroundColor: colors.blue[100],
      },
      filledTrack: {
        background: `linear-gradient(270deg, ${colors.blue[500]} 0%, #CF39DC 100%)`,
      },
    },
  },
  defaultProps: {},
};
