/* eslint-disable @typescript-eslint/ban-types */
import {type QueryKey, useQuery} from '@tanstack/react-query';
import {
  type AuthError,
  getRedirectResult,
  type UserCredential,
} from 'firebase/auth';
import {type FirebaseError} from 'firebase/app';
import {firebaseAuth} from '../auth/auth';
import {convertToJpFirebaseError} from '../services/convert-to-jp-firebase-error';
import {type UseFirebaseQueryOptions} from '../types/types';

export const createUseGetRedirectResultKey = (): QueryKey => [
  '/firebase/getRedirectResult',
];

export const useGetRedirectResult = (
  // ロードが未完了の場合、undefined が返る。
  // そのため、リクエスト成功時には null を返すようにする。
  // https://tanstack.com/query/v4/docs/react/guides/migrating-to-react-query-4#undefined-is-an-illegal-cache-value-for-successful-queries
  options?: UseFirebaseQueryOptions<UserCredential | null>,
) => {
  return useQuery<UserCredential | null, AuthError | FirebaseError | Error>({
    queryKey: createUseGetRedirectResultKey(),
    async queryFn() {
      return getRedirectResult(firebaseAuth);
    },
    gcTime: 0,
    ...options,
  });
};
