import React from 'react';
import {Box, type BoxProps, useStyles} from '@chakra-ui/react';

export type CardDescriptionProps = Record<string, unknown> & BoxProps;

export const CardDescription: React.FC<CardDescriptionProps> = (props) => {
  const styles = useStyles();

  return <Box __css={styles.cardDescription} {...props} />;
};
