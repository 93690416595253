// Breakpointsには、sm、md、lg、xl などの通常のエイリアスを使用する
export const breakpoints = {
  // Mobileのmin(iPhone SE相当)
  sm: '320px',

  // Mobileのnormal(iPhone相当)
  md: '384px',

  // Mobileのmax(iPhone Plus + マージン)
  lg: '450px',

  // Tablet(iPad mini相当)
  xl: '768px',

  '2xl': '1200px',
};
