import React from 'react';
import Head from 'next/head';
import {serviceDescription, serviceNameFull} from '../../../const/label';

type MetaTagsProps = {
  readonly title?: string;
  readonly description?: string;
};

export const MetaTags: React.FC<MetaTagsProps> = (props) => {
  const {title, description = serviceDescription} = props;

  const serviceTitle = title
    ? `${title} - ${serviceNameFull}`
    : serviceNameFull;

  return (
    <Head>
      <title>{serviceTitle}</title>
      <meta property="og:title" content={serviceTitle} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
    </Head>
  );
};
