import {type AxiosResponse} from 'axios';
import {type QueryKey, useQuery} from '@tanstack/react-query';
import {
  fetchHasSession,
  type FetchHasSessionResponse,
} from 'dp-account/src/features/id-token/repositories/account/fetch-has-session';
import {accountServerGraphQl} from '../../../const/env';
import {type UseAxiosQueryOptions} from '../../../types/react-query';

export type UseFetchHasSessionCache = AxiosResponse<FetchHasSessionResponse>;

export const createUseHasSessionKey = (): QueryKey => [
  accountServerGraphQl,
  {functionKey: 'useFetchHasSession'},
];

export const useFetchHasSession = (
  options?: UseAxiosQueryOptions<UseFetchHasSessionCache>,
) => {
  return useQuery({
    queryKey: createUseHasSessionKey(),
    async queryFn() {
      return fetchHasSession(accountServerGraphQl);
    },
    ...options,
  });
};
