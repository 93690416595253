import {useDpErrorToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {useEffect, useState} from 'react';
import {useSignInStatus} from './use-has-sign-in';
import {useSignOut} from './use-sign-out';

export const useSignInStatusCheck = () => {
  const errorToast = useDpErrorToast();

  const [enableSignInStatusCheck, setEnableSignInStatusCheck] = useState(true);

  const signInStatus = useSignInStatus();

  const {signOut} = useSignOut({
    onSuccess() {
      // イレギュラーケースでログアウトした場合
      // 無限ループを防ぐためリロードは行わず、メッセージ表示のみとする
      errorToast({
        title: 'ログインのエラーが発生しました。再度画面を読み込んでください。',
      });
    },
    onError(key, error) {
      errorToast({
        title: error.message,
      });
    },
  });

  useEffect(() => {
    if (signInStatus === 'unknown' || !enableSignInStatusCheck) {
      return;
    }

    // ログイン状態の判定は、読み込み時の一度のみの判定とする
    // ログインやログアウトの処理中の段階では、正常/異常の判定が正常にできないため
    setEnableSignInStatusCheck(false);

    // ログイン状態に異常があるため、ログアウトをする
    if (signInStatus === 'accountOnly') {
      signOut();
    }
  }, [signInStatus, enableSignInStatusCheck, signOut]);
};
