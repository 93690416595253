export const fromQueryKey = 'from';

export const fromQueryValues = ['work', 'academia'] as const;

export type FromQueryValue = (typeof fromQueryValues)[number];

export const callbackQueryKey = 'callback-url';

export const signUpCallbackQueryKey = 'sign-up-callback-url';

export const emailQueryKey = 'email';
