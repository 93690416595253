import React from 'react';
import {Box, type BoxProps, useStyles} from '@chakra-ui/react';

type CardTitleProps = Record<string, unknown> & BoxProps;

export const CardTitle: React.FC<CardTitleProps> = (props) => {
  const styles = useStyles();

  return <Box as="h2" __css={styles.cardTitle} {...props} />;
};
