import React from 'react';
import {Box, type BoxProps, useStyles} from '@chakra-ui/react';

export type CardHeaderProps = Record<string, unknown> & BoxProps;

export const CardHeader: React.FC<CardHeaderProps> = (props) => {
  const styles = useStyles();

  return <Box __css={styles.cardHeader} {...props} />;
};
