import {useToast, type UseToastOptions} from '@chakra-ui/react';

export const dpToastOptions: Readonly<UseToastOptions> = {
  position: 'top',
  duration: 4000,
};

export const dpErrorToastOptions: Readonly<UseToastOptions> = {
  ...dpToastOptions,
  status: 'error',
  duration: 10_000,
};

export const useDpToast = (options?: UseToastOptions) => {
  return useToast({...dpToastOptions, ...options});
};

export const useDpErrorToast = (options?: Omit<UseToastOptions, 'status'>) => {
  return useToast({...dpErrorToastOptions, ...options});
};
