// Design tokensについて
// @see https://spectrum.adobe.com/page/design-tokens/

// figmaでのカラー定義一覧
// @see https://www.figma.com/file/SbpYR1GtqpcFOdtWYiYxmT/%5BPrime%5D-Setting-%3A-Common?node-id=1%3A23
const globalTokens = {
  white: '#FFF',
  black: '#2D2D2D',
  brand: '#036EB8',

  blueGray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#8E9EB1',
    500: '#72859D',
    600: '#5D738F',
    700: '#3F526A',
    800: '#263140',
    900: '#0D1015',
  },

  blue: {
    50: '#F5F8FE',
    100: '#E1EAFC',
    200: '#C3D4F9',
    300: '#88AAF3',
    400: '#3971EA',
    500: '#074DE5',
    600: '#0647D3',
    700: '#063EB7',
    800: '#043192',
    900: '#03256E',
  },

  red: {
    50: '#FFF8F9',
    100: '#FDE9ED',
    200: '#FCD3DB',
    300: '#F8A6B7',
    400: '#F46B86',
    500: '#F14668',
    600: '#DE4060',
    700: '#C13853',
    800: '#9A2D43',
    900: '#742232',
  },

  yellow: {
    50: '#FFFDF7',
    100: '#FEFAE7',
    200: '#FDF6CE',
    300: '#FBEC9E',
    400: '#F9E05D',
    500: '#F7D835',
    600: '#E3C731',
    700: '#C6AD2A',
    800: '#9E8A22',
    900: '#776819',
  },

  green: {
    50: '#F5FCF9',
    100: '#E2F5EC',
    200: '#C5EAD9',
    300: '#8BD6B2',
    400: '#3DBA7F',
    500: '#0DA95F',
    600: '#0B9554',
    700: '#0A874C',
    800: '#086C3D',
    900: '#06512E',
  },

  orange: {
    50: '#FEF9F8',
    100: '#FDEDE9',
    200: '#FBDBD3',
    300: '#F8B8A7',
    400: '#F3886C',
    500: '#F06A47',
    600: '#DD6241',
    700: '#C05539',
    800: '#9A442D',
    900: '#733322',
  },

  gray: {
    50: '#F4F4F4',
    100: '#EAEAEA',
    200: '#DEDEDE',
    300: '#D4D4D4',
    400: '#BDBDBD',
    500: '#AAAAAA',
    600: '#8D8D8D',
    700: '#6D6D6D',
    800: '#4D4D4D',
    900: '#242424',
  },

  // TODO: alpha color
  // whiteAlpha: {
  //   50: '',
  //   100: '',
  //   200: '',
  //   300: '',
  //   400: '',
  //   500: '',
  //   600: '',
  //   700: '',
  //   800: '',
  //   900: '',
  // },

  blackAlpha: {
    50: 'rgba(0,0,0,0.04)',
    100: 'rgba(0,0,0,0.08)',
    200: 'rgba(0,0,0,0.12)',
    300: 'rgba(0,0,0,0.16)',
    400: 'rgba(0,0,0,0.24)',
    500: 'rgba(0,0,0,0.36)',
    600: 'rgba(0,0,0,0.48)',
    700: 'rgba(0,0,0,0.64)',
    800: 'rgba(0,0,0,0.80)',
    900: 'rgba(0,0,0,0.92)',
  },

  apple: {
    50: '#D6D6D6',
    100: '#C2C2C2',
    200: '#ADADAD',
    300: '#999999',
    400: '#858585',
    500: '#000000',
    600: '#333333',
    700: '#474747',
    800: '#5C5C5C',
    900: '#707070',
  },

  fireGrad: {
    50: '#709BF9',
    100: '#5285F3',
    200: '#2966EB',
    300: '#0A50E6',
    400: '#074DE5',
    500: '#4947E2',
    600: '#6944E1',
    700: '#8840E0',
    800: '#AA3DDE',
    900: '#CF39DC',
  },
} as const;

const aliasTokens = {
  fontColor: {
    main: globalTokens.black,
    sub: globalTokens.blueGray[700],
    link: globalTokens.blue[500],
    error: globalTokens.red[500],
    required: globalTokens.red[500],
    important: globalTokens.red[500],
  },
  border: {
    main: globalTokens.blueGray[300],
    sub: globalTokens.blueGray[100],
  },
  selectedBackgroundColor: {
    sub: globalTokens.blueGray[100],
  },
} as const;

const componentSpecificTokens = {} as const;

export const colors = {
  ...globalTokens,
  ...aliasTokens,
  ...componentSpecificTokens,
};
