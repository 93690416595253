import React from 'react';
import {firebaseAuth} from '../../../packages/firebase-core/src/auth/auth';
import {useSignInAnonymously} from '../../../packages/firebase-core/src/hooks/use-sign-in-anonymously';

type SignInAnonymouslyProps = {
  readonly children?: React.ReactNode;
};

export const SignInAnonymously: React.FC<SignInAnonymouslyProps> = (props) => {
  const {children} = props;

  useSignInAnonymously(firebaseAuth);

  return <>{children}</>;
};
