import {type ComponentMultiStyleConfig} from '@chakra-ui/react';

export const Select: ComponentMultiStyleConfig = {
  parts: ['field'],
  baseStyle: {},
  sizes: {
    md: {
      field: {
        // デフォルトフォントサイズをInputと同一のlg(16px)に合わせる
        fontSize: 'lg',
      },
    },
  },
  variants: {},
  defaultProps: {},
};
