const base = `
  "Helvetica Neue",
  Arial,
  "Hiragino Kaku Gothic ProN",
  "Hiragino Sans",
  "BIZ UDPGothic",
  Meiryo,
  sans-serif;
`;

export const fonts = {
  body: base,
  heading: base,
  mono: base,
} as const;
