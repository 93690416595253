// @see https://www.figma.com/file/SbpYR1GtqpcFOdtWYiYxmT/%5BPrime%5D-Setting-%3A-Common?node-id=6%3A3&t=2x5o4MhQK6bZnzC6-4
export const radii = {
  none: '0',
  full: '9999px',

  // 2px
  sm: '0.125rem',

  // 4px
  base: '0.25rem',

  // 6px
  md: '0.375rem',

  // 8px
  lg: '0.5rem',

  // 12px
  xl: '0.75rem',

  // 16px
  '2xl': '1rem',

  // 20px
  '3xl': '1.25rem',
} as const;
