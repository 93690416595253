import {useRouter} from 'next/router';
import {useEffect} from 'react';
import {useSignInStatus} from '../packages/account-server/hooks/use-has-sign-in';

type UseRequireAnonymousResult = {
  isLoading: boolean;
};

export const useRequireAnonymous = (): UseRequireAnonymousResult => {
  const router = useRouter();

  const signInStatus = useSignInStatus();

  useEffect(() => {
    if (signInStatus === 'signIn') {
      void router.push({
        pathname: '/profile',
        query: router.query,
      });
    }
  }, [signInStatus, router]);

  return {
    isLoading: signInStatus === 'unknown',
  };
};
