import {Badge} from './badge';
import {Button} from './button';
import {Card} from './card';
import {Input} from './input';
import {Progress} from './progress';
import {Select} from './select';
import {Tabs} from './tabs';
import {Tag} from './tag';
import {Textarea} from './textarea';
import {Toast} from './toast';

export const components = {
  // Abc order
  Badge,
  Button,
  Card,
  Input,
  Progress,
  Select,
  Tabs,
  Tag,
  Textarea,
  Toast,
};
