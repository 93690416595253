import {extendTheme} from '@chakra-ui/react';
import {breakpoints} from './breakpoints';
import {colors} from './colors';
import {fontSizes} from './font-sizes';
import {fonts} from './fonts';
import {components} from './components';
import {styles} from './styles';
import {radii} from './borders';

const baseOverrides = {
  breakpoints,
  radii,
  colors,
  fontSizes,
  fonts,
  components,
  styles,
};

export const customTheme = extendTheme(baseOverrides);

export const createCustomTheme = (overrides: Record<string, any>) => {
  return extendTheme({
    ...baseOverrides,
    ...overrides,
  });
};

export default customTheme;
