// @see https://www.figma.com/file/SbpYR1GtqpcFOdtWYiYxmT/%5BPrime%5D-Setting-%3A-Common?node-id=1%3A708
export const fontSizes = {
  // 11px
  xs: '0.6875rem',

  // 12px
  sm: '0.75rem',

  // 14px
  md: '0.875rem',

  // 16px
  lg: '1rem',

  // 20px
  xl: '1.25rem',

  // 24px
  '2xl': '1.5rem',

  // 32px
  '3xl': '2rem',

  // 36px
  '4xl': '2.25rem',

  // 48px
  '5xl': '3rem',

  // 64px
  '6xl': '4rem',

  // Chakra-ui default sizes
  '7xl': '4.5rem',
  '8xl': '6rem',
  '9xl': '8rem',
} as const;
