import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {
  callbackQueryKey,
  fromQueryKey,
  type FromQueryValue,
  fromQueryValues,
  signUpCallbackQueryKey,
} from 'dp-account/src/const/url-query';
import {createQueryString} from 'dp-account/src/utils/create-query-string';
import {getQueryValue} from 'dp-account/src/utils/get-query-value';

export const useLineUrl = () => {
  const router = useRouter();
  const [lineUrl, setLineUrl] = useState('');

  // Locationからoriginを取得するため、クライアントでのみ値を作成する
  useEffect(() => {
    const from = getQueryValue(router.query[fromQueryKey]);
    const callbackUrl = getQueryValue(router.query[callbackQueryKey], {
      decode: true,
    });
    const signUpcallbackUrl = getQueryValue(
      router.query[signUpCallbackQueryKey],
      {decode: true},
    );

    const lineCallbackQuery: Record<string, string | undefined> = {};
    lineCallbackQuery[fromQueryKey] = from;
    lineCallbackQuery[callbackQueryKey] = encodeURIComponent(callbackUrl ?? '');
    lineCallbackQuery[signUpCallbackQueryKey] = encodeURIComponent(
      signUpcallbackUrl ?? '',
    );

    const lineCallbackQueryString = createQueryString(lineCallbackQuery);
    const lineCallbackUrl = `${window.location.origin}/line-callback${
      lineCallbackQueryString ? `?${lineCallbackQueryString}` : ''
    }`;

    const channelKind: FromQueryValue | undefined = fromQueryValues.includes(
      from,
    )
      ? from
      : undefined;

    const queryString = createQueryString({
      channelKind,
      callbackUrl: encodeURIComponent(lineCallbackUrl),
    });

    setLineUrl(
      `${process.env.NEXT_PUBLIC_ACCOUNT_SERVER}/auth/line/authorize?${queryString}`,
    );
  }, [router.query]);

  return lineUrl;
};
