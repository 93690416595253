import {type Auth, signInAnonymously} from 'firebase/auth';
import {useEffect, useState} from 'react';

type UseSignInAnonymously = {
  error?: Error;
};

export const useSignInAnonymously = (auth: Auth): UseSignInAnonymously => {
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (newFirebaseUser) => {
        // 匿名認証機能で、Firebaseに必ずログインしている(firebaseUserが存在する)状態とする
        if (!newFirebaseUser) {
          try {
            await signInAnonymously(auth);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setError(error);
            }

            setError(new Error('認証に失敗しました。(signInAnonymously)'));
          }
        }
      },
      (error) => {
        setError(error);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    error,
  };
};
