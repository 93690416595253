import React from 'react';
import {Layout} from '../../domain/Layout/Layout';
import {AuthCard} from '../../domain/AuthCard/AuthCard';
import {RequireAnonymous} from '../../functional/RequireAnonymous/RequireAnonymous';
import {baseAuthPageMaxWidth} from '../../../const/layout';

export const TopPage = () => {
  return (
    <RequireAnonymous>
      <Layout containerProps={{maxWidth: baseAuthPageMaxWidth}}>
        <AuthCard />
      </Layout>
    </RequireAnonymous>
  );
};
