import {type ComponentMultiStyleConfig} from '@chakra-ui/react';

export const Tag: ComponentMultiStyleConfig = {
  parts: ['container', 'label', 'closeButton'],
  baseStyle: {
    container: {
      fontWeight: 'bold',
    },
  },
  sizes: {
    md: {
      container: {
        borderRadius: 'sm',
      },
    },
  },
  variants: {},
  defaultProps: {},
};
