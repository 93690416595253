import {firebaseAuth} from '../../firebase-core/src/auth/auth';
import {useFirebaseUser} from '../../firebase-core/src/hooks/use-firebase-user';
import {useFetchHasSession} from './use-fetch-has-session';

type SignInStatus =
  | 'signIn'
  | 'signOut'
  | 'firebaseOnly'
  | 'accountOnly'
  | 'unknown';

export const useSignInStatus = (): SignInStatus => {
  const {firebaseUser} = useFirebaseUser(firebaseAuth);
  const {data} = useFetchHasSession();

  const hasSession = data?.data.data.hasSession;
  const isUnknownSignInStatus =
    firebaseUser === undefined || hasSession === undefined;

  if (isUnknownSignInStatus) {
    return 'unknown';
  }

  if (hasSession) {
    if (firebaseUser.isAnonymous) {
      // DPのみログイン済。異常系
      return 'accountOnly';
    }

    // Firebase、DP 共にログイン済
    return 'signIn';
  }

  if (firebaseUser.isAnonymous) {
    // Firebase、DP 共に未ログイン
    return 'signOut';
  }

  // Firebaseのみログイン状態
  // 新規作成時など、一時的にこのstatusになる
  return 'firebaseOnly';
};
