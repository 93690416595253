import React from 'react';
import {Box, type BoxProps} from '@chakra-ui/react';
import {basePageMaxWidth} from '../../../const/layout';

type LayoutProps = {
  readonly children?: React.ReactNode;
  readonly containerProps?: BoxProps;
};

export const Layout: React.FC<LayoutProps> = (props) => {
  const {children, containerProps} = props;

  return (
    <Box
      marginY={{base: 5, lg: 20}}
      paddingX={{base: 0, lg: 10}}
      maxWidth={basePageMaxWidth}
      marginInline="auto"
      {...containerProps}
    >
      {children}
    </Box>
  );
};
