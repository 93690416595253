export const createQueryString = (
  query: Record<string, string | string[] | undefined>,
) => {
  return Object.keys(query)
    .map((key) => {
      const values = query[key];
      if (typeof values === 'string') {
        if (values) {
          return `${key}=${values}`;
        }

        return undefined;
      }

      if (values) {
        return values
          .map((value) => {
            return `${key}=${value}`;
          })
          .join('&');
      }

      return undefined;
    })
    .filter(Boolean)
    .join('&');
};
