import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appVersion: process.env.npm_package_version,
  releaseStage: process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'local',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

type BugSnagErrorBoundaryProps = {
  readonly children?: React.ReactNode;
};

export const BugSnagErrorBoundary: React.FC<BugSnagErrorBoundaryProps> = (
  props,
) => {
  const {children} = props;

  if (ErrorBoundary) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }

  return <>{children}</>;
};
