import React from 'react';
import {EmailIcon} from '@chakra-ui/icons';
import {
  FormInput,
  type FormInputProps,
} from '../../hook-form/FormInput/FormInput';

export type FormEmailInputProps = Record<string, unknown> & FormInputProps;

export const FormEmailInput: React.FC<FormEmailInputProps> = (props) => {
  const {inputProps, ...formInputProps} = props;

  return (
    <FormInput
      autoComplete="email"
      leftIcon={<EmailIcon color="gray.200" />}
      inputProps={{
        placeholder: 'メールアドレス',
        ...inputProps,
      }}
      {...formInputProps}
    />
  );
};
