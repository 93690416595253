import React, {ComponentProps} from 'react';
import {Button} from '@chakra-ui/react';
import {LineLogoIcon} from 'dp-chakra/src/components/icons/LineLogoIcon';
import {useLineUrl} from '../../../packages/account-server/hooks/use-line-url';

export const SignInWithLineButton: React.FC = () => {
  const lineUrl = useLineUrl();

  return (
    <Button
      as="a"
      variant="outline"
      height="44px"
      colorScheme="blueGray"
      href={lineUrl}
      leftIcon={
        <LineLogoIcon position="absolute" top={2} left={1.5} boxSize={7} />
      }
    >
      LINEで続ける
    </Button>
  );
};
