/* eslint-disable unicorn/prevent-abbreviations */
import React from 'react';
import {Box, Text} from '@chakra-ui/react';

// 仮のロゴ
// SVGに差し替え後、削除
export const AccountLogo = () => {
  return (
    <Box display="flex" alignItems="center" whiteSpace="nowrap">
      <Text as="span" fontSize="xl" fontWeight="bold">
        Dr.&apos;s Prime
      </Text>
      <Text as="span" fontSize="md" fontWeight="medium" ml={2} color="brand">
        アカウント
      </Text>
    </Box>
  );
};
