import axios, {type AxiosResponse} from 'axios';
import {type GraphQlRequest} from './request';

export type FetchHasSessionResponse = {
  data: {
    hasSession: boolean;
  };
};

export const fetchHasSession = async (url: string) => {
  const response = await axios.post<
    any,
    AxiosResponse<FetchHasSessionResponse>,
    GraphQlRequest
  >(
    url,
    {
      query: `
query {
  hasSession
}
`,
    },
    {
      withCredentials: true,
    },
  );

  return response;
};
