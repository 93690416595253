import {type Auth, type User} from 'firebase/auth';
import {useEffect, useState} from 'react';

type UseFirebaseUserOptions = {
  onAuthStateChanged?: (firebaseUser?: User) => void;
  onError?: (error?: Error) => void;
};

type UseFirebaseUserHook = {
  firebaseUser?: User;
  loading: boolean;
  error?: Error;
};

export const useFirebaseUser = (
  auth: Auth,
  options?: UseFirebaseUserOptions,
): UseFirebaseUserHook => {
  const [firebaseUser, setFirebaseUser] = useState<User>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (newFirebaseUser) => {
        options?.onAuthStateChanged?.(newFirebaseUser ?? undefined);
        setFirebaseUser(newFirebaseUser ?? undefined);
      },
      (error) => {
        options?.onError?.(error);
        setError(error);
      },
    );

    return () => {
      unsubscribe();
    };

    // Authの変更時のみ、検知する
  }, [auth]);

  return {
    firebaseUser,
    loading: !firebaseUser && !error,
    error,
  };
};
