export const getQueryValue = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  value?: string | string[] | null,
  options?: {
    decode?: boolean;
  },
): string | undefined => {
  const firstValue = value && (typeof value === 'string' ? value : value[0]);
  if (firstValue) {
    return options?.decode ? decodeURIComponent(firstValue) : firstValue;
  }
};
