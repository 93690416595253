import {type ComponentSingleStyleConfig} from '@chakra-ui/react';

export const Textarea: ComponentSingleStyleConfig = {
  baseStyle: {},
  sizes: {
    md: {
      // デフォルトフォントサイズをlg(16px)とする
      // mobile safariだとinputは16px未満だと小さいと判断され、focus時に自動でズームされるため
      fontSize: 'lg',
    },
  },
  variants: {},
  defaultProps: {},
};
