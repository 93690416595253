import {type FirebaseError} from 'firebase/app';
import {
  type AuthError,
  getAdditionalUserInfo,
  type UserCredential,
} from 'firebase/auth';
import {useEffect} from 'react';
import {useDpToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {useRouter} from 'next/router';
import {firebaseAuth} from '../../firebase-core/src/auth/auth';
import {useGetRedirectResult} from '../../firebase-core/src/hooks/use-get-redirect-result';
import {useSessionCreate} from './use-session-create';

type HandleRedirectionOptions = {
  onSignIn?: () => void;
};

type HandleRedirectionResult = {
  userCredentialOfRedirectResult?: UserCredential;
  isLoading: boolean;
  error?: AuthError | FirebaseError | Error;
};

export const useHandleRedirection = (
  options?: HandleRedirectionOptions,
): HandleRedirectionResult => {
  const dpToast = useDpToast();
  const router = useRouter();

  const {
    mutate: sessionCreate,
    isPending: isSessionCreateLoading,
    error: sessionCreateError,
  } = useSessionCreate({
    onSuccess: options?.onSignIn,
  });

  const {
    data: userCredentialOfRedirectResult,
    isLoading: isGetRedirectResultLoading,
    error: getRedirectResultError,
    status: getRedirectResultStatus,
  } = useGetRedirectResult();

  // ユーザー作成成功時の処理
  useEffect(() => {
    if (!userCredentialOfRedirectResult) {
      return;
    }

    if (getRedirectResultStatus === 'success') {
      const userInfo = getAdditionalUserInfo(userCredentialOfRedirectResult);
      if (userInfo?.isNewUser) {
        if (firebaseAuth.currentUser?.isAnonymous) {
          // 匿名ユーザー作成時の処理
        } else {
          // ユーザー作成時の処理

          // 通常コンポーネント内でトーストは表示する
          // ただし、useQueryに関してはonSuccessが使えないため、ここで表示する
          dpToast({
            title: 'Dr.’s Primeアカウントを作成しました',
          });

          void router.push({
            pathname: '/user-create',
            query: router.query,
          });
        }
      } else {
        sessionCreate();
      }
    }
    // 関数は依存関係に含めない。
    // dpToast関数など毎回再定義される関数があるため
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRedirectResultStatus, userCredentialOfRedirectResult]);

  const error = getRedirectResultError ?? sessionCreateError ?? undefined;
  const isLoading = isGetRedirectResultLoading || isSessionCreateLoading;

  return {
    userCredentialOfRedirectResult: userCredentialOfRedirectResult ?? undefined,
    isLoading,
    error,
  };
};
