import React from 'react';
import {Icon} from '@chakra-ui/react';
import {type IconProps} from './types';

export const LineLogoIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 28 28" {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 12.517C24 7.82074 19.5141 4 14 4C8.48683 4 4 7.82074 4 12.517C4 16.7273 7.55797 20.2529 12.3635 20.9198C12.6887 20.9936 13.1321 21.1451 13.2445 21.4373C13.3458 21.7029 13.3102 22.118 13.2773 22.3866C13.2773 22.3866 13.1602 23.1273 13.1349 23.2847C13.0908 23.5503 12.9343 24.3225 14.0009 23.8503C15.0685 23.3781 19.7597 20.2913 21.8573 17.7572C23.3055 16.0898 24 14.3969 24 12.517Z"
          fill="#06C755"
        />
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#06C755"
        />
        <path
          d="M24 12.517C24 7.82074 19.5141 4 14 4C8.48683 4 4 7.82074 4 12.517C4 16.7273 7.55797 20.2529 12.3635 20.9198C12.6887 20.9936 13.1321 21.1451 13.2445 21.4373C13.3458 21.7029 13.3102 22.118 13.2773 22.3866C13.2773 22.3866 13.1602 23.1273 13.1349 23.2847C13.0908 23.5503 12.9343 24.3225 14.0009 23.8503C15.0685 23.3781 19.7597 20.2913 21.8573 17.7572C23.3055 16.0898 24 14.3969 24 12.517Z"
          fill="white"
        />
        <path
          d="M20.673 15.2305H17.8677H17.8639C17.758 15.2305 17.6727 15.141 17.6727 15.0299V15.0269V10.4546V10.4507C17.6727 10.3395 17.758 10.25 17.8639 10.25H17.8677H20.673C20.778 10.25 20.8642 10.3405 20.8642 10.4507V11.1953C20.8642 11.3065 20.7789 11.396 20.673 11.396H18.7637V12.1692H20.673C20.778 12.1692 20.8642 12.2597 20.8642 12.3699V13.1146C20.8642 13.2257 20.7789 13.3152 20.673 13.3152H18.7637V14.0884H20.673C20.778 14.0884 20.8642 14.1789 20.8642 14.2891V15.0338C20.8642 15.141 20.7789 15.2305 20.673 15.2305Z"
          fill="#06C755"
        />
        <path
          d="M10.281 15.2296C10.386 15.2296 10.4722 15.1401 10.4722 15.0289V14.2842C10.4722 14.1741 10.386 14.0835 10.281 14.0835H8.37173V10.4477C8.37173 10.3376 8.2855 10.2471 8.18053 10.2471H7.47099C7.36508 10.2471 7.27979 10.3366 7.27979 10.4477V15.025V15.0279C7.27979 15.1391 7.36508 15.2286 7.47099 15.2286H7.47474H10.281V15.2296Z"
          fill="#06C755"
        />
        <path
          d="M11.9724 10.248H11.2629C11.1579 10.248 11.0717 10.3376 11.0717 10.4487V15.0289C11.0717 15.1391 11.1569 15.2296 11.2629 15.2296H11.9724C12.0783 15.2296 12.1636 15.14 12.1636 15.0289V10.4487C12.1636 10.3385 12.0783 10.248 11.9724 10.248Z"
          fill="#06C755"
        />
        <path
          d="M16.7999 10.248H16.0904C15.9845 10.248 15.8992 10.3376 15.8992 10.4487V13.1687L13.9027 10.3385C13.898 10.3317 13.8924 10.3248 13.8877 10.3179L13.8868 10.3169C13.883 10.313 13.8793 10.3081 13.8755 10.3041C13.8746 10.3031 13.8727 10.3022 13.8718 10.3012C13.868 10.2982 13.8652 10.2953 13.8615 10.2923C13.8596 10.2913 13.8577 10.2894 13.8559 10.2884C13.8531 10.2854 13.8493 10.2835 13.8456 10.2815C13.8437 10.2805 13.8418 10.2785 13.8399 10.2776C13.8362 10.2756 13.8334 10.2736 13.8296 10.2717C13.8277 10.2707 13.8259 10.2697 13.824 10.2687C13.8202 10.2667 13.8165 10.2648 13.8127 10.2638C13.8109 10.2628 13.809 10.2618 13.8062 10.2618C13.8024 10.2608 13.7987 10.2589 13.7949 10.2579C13.7931 10.2569 13.7903 10.2569 13.7884 10.2559C13.7846 10.2549 13.7809 10.2539 13.7771 10.253C13.7743 10.252 13.7715 10.252 13.7687 10.252C13.7649 10.251 13.7621 10.251 13.7584 10.251C13.7546 10.251 13.7518 10.251 13.7481 10.25C13.7462 10.25 13.7434 10.25 13.7415 10.25H13.0367C12.9317 10.25 12.8455 10.3395 12.8455 10.4507V15.0309C12.8455 15.141 12.9308 15.2315 13.0367 15.2315H13.7462C13.8521 15.2315 13.9374 15.142 13.9374 15.0309V12.3109L15.9367 15.145C15.9507 15.1656 15.9676 15.1823 15.9863 15.1951C15.9873 15.1961 15.9882 15.1961 15.9882 15.1971C15.992 15.2001 15.9957 15.202 16.0004 15.205C16.0023 15.206 16.0041 15.2069 16.006 15.2079C16.0088 15.2099 16.0126 15.2109 16.0154 15.2128C16.0182 15.2148 16.022 15.2158 16.0248 15.2168C16.0266 15.2178 16.0285 15.2187 16.0304 15.2187C16.0351 15.2207 16.0388 15.2217 16.0435 15.2227C16.0444 15.2227 16.0454 15.2237 16.0463 15.2237C16.0623 15.2276 16.0791 15.2305 16.096 15.2305H16.8008C16.9058 15.2305 16.9921 15.141 16.9921 15.0299V10.4497C16.9911 10.3385 16.9058 10.248 16.7999 10.248Z"
          fill="#06C755"
        />
      </svg>
    </Icon>
  );
};
