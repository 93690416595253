import {FirebaseError} from 'firebase/app';
import {type AuthError} from 'firebase/auth';
import {firebaseErrorMessageMap} from '../types/types';

const createJpFirebaseErrorMessage = (firebaseError: FirebaseError): string => {
  const errorMessage = firebaseErrorMessageMap[firebaseError.code];

  if (errorMessage) {
    return errorMessage;
  }

  // 日本語に変換されていないエラーのため、Bugsnagを送信する
  // const error = new Error(
  // `Firebase: There is no translation for firebase code. (${firebaseError.code})`,
  // );
  // Bugsnag.notify(error);

  return `エラーが発生しました。(${firebaseError.code})`;
};

export const convertToJpFirebaseError = (
  error: AuthError | FirebaseError | Error,
): AuthError | FirebaseError | Error => {
  if (error instanceof FirebaseError) {
    const jpFirebaseErrorMessage = createJpFirebaseErrorMessage(error);
    const firebaseError = new FirebaseError(
      error.code,
      jpFirebaseErrorMessage,
      error.customData,
    );
    return firebaseError;
  }

  // 意図しないエラーのためログを送信する
  // if (error instanceof Error) {
  //   Bugsnag.notify(error);
  // } else {
  //   Bugsnag.notify(
  //     new Error(
  //       'Failure to update email address due to an error of unknown reason.',
  //     ),
  //   );
  // }

  return error;
};
