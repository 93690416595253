import React from 'react';
import {Button} from '@chakra-ui/react';
import {OAuthProvider} from 'firebase/auth';
import {FirebaseError} from 'firebase/app';
import {AppleLogoIcon} from 'dp-chakra/src/components/icons/AppleLogoIcon';
import {useDpErrorToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {useSignInWithRedirect} from '../../../packages/firebase-core/src/hooks/use-sign-in-with-redirect';

export const SignInWithAppleButton = () => {
  const dpErrorToast = useDpErrorToast();

  const {mutate: signInWithRedirectMutation} = useSignInWithRedirect({
    onError(error) {
      if (error instanceof FirebaseError) {
        dpErrorToast({
          title: error.message,
        });
      } else {
        dpErrorToast({
          title: 'Apple ログインに失敗しました。',
        });
      }
    },
  });

  const handleClick = () => {
    const provider = new OAuthProvider('apple.com');
    signInWithRedirectMutation({provider});
  };

  return (
    <Button
      height="44px"
      colorScheme="apple"
      leftIcon={
        <AppleLogoIcon position="absolute" top={2} left={1.5} boxSize={7} />
      }
      onClick={handleClick}
    >
      Appleで続ける
    </Button>
  );
};
