import React from 'react';
import {
  Box,
  type BoxProps,
  StylesProvider,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export type CardProps = {
  readonly size?: 'md' | 'lg';
} & BoxProps;

export const Card: React.FC<CardProps> = (props) => {
  const {children, size, ...boxProps} = props;
  const styles = useMultiStyleConfig('Card', {size});

  return (
    <Box __css={styles.card} {...boxProps}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
};
