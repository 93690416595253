import {type ComponentMultiStyleConfig} from '@chakra-ui/react';

export const Input: ComponentMultiStyleConfig = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      _readOnly: {
        backgroundColor: 'blueGray.100',
      },
    },
  },
  sizes: {
    md: {
      field: {
        // デフォルトフォントサイズをlg(16px)とする
        // mobile safariだとinputは16px未満だと小さいと判断され、focus時に自動でズームされるため
        fontSize: 'lg',
      },
    },
  },
  variants: {},
  defaultProps: {},
};
