import Cookies from 'js-cookie';
import {isSsr} from 'dp-nextjs/src/utils/is';
import {getDomainForGtmUserId} from './get-domain-for-gtm-user-id';
import {getGtmUserIdKey} from './get-gtm-user-id-key';

// GTMのログ送信時にCookieの値を参照する設定のため、
// ログアウト時に必ずCookieを削除する
// @see https://support.google.com/tagmanager/answer/4565987?hl=ja
export const removeFirebaseUserIdFromCookie = () => {
  if (isSsr) {
    return;
  }

  const key = getGtmUserIdKey();
  const domain = getDomainForGtmUserId();

  Cookies.remove(key, {
    secure: true,
    domain,
  });
};
