import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {ChakraProvider, CSSReset} from '@chakra-ui/react';
import type {AppProps} from 'next/app';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import Script from 'next/script';
import {initDayjs} from 'dp-chakra/src/utils/dayjs';
import {createApolloClient} from '../repositories/apollo/create-apollo-client';
import {SignInStatusCheck} from '../components/functional/SignInStatusCheck/SignInStatusCheck';
import {SignInAnonymously} from '../components/functional/SignInAnonymously/SignInAnonymously';
import {accountCustomTheme} from '../theme';
import {BugSnagErrorBoundary} from '../components/functional/BugSnagErrorBoundary/BugSnagErrorBoundary';
import {MetaTags} from '../components/core/MetaTags/MetaTags';

const apolloClient = createApolloClient();
const queryClient = new QueryClient();

initDayjs();

const AccountApp = ({Component, pageProps}: AppProps) => {
  return (
    <>
      <GoogleTagManger />

      <MetaTags />

      <BugSnagErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={apolloClient}>
            <ChakraProvider theme={accountCustomTheme}>
              <SignInStatusCheck>
                <SignInAnonymously>
                  <CSSReset />
                  <Component {...pageProps} />
                </SignInAnonymously>
              </SignInStatusCheck>
            </ChakraProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </BugSnagErrorBoundary>
    </>
  );
};

const GoogleTagManger: React.FC = () => {
  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
      `}
    </Script>
  );
};

export default AccountApp;
